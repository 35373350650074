












import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { useI18n } from '@/composables/useI18n';
import { ContactPages } from '@/router/modules/contact';

export default defineComponent({
  name: 'ListBox',
  props: {
    title: String,
    desc: String,
    btnText: String,
    iconName: String,
    idx: Number
  },
  setup(props) {
    const { getLocale } = useI18n();
    const listBoxRef: Ref<HTMLLIElement | null> = ref(null);

    const buttonClickHandler = () => {
      window.open(`${ContactPages.ticketPage}?lang=${getLocale()}`, '_blank');
    };

    onMounted(() => {
      if (listBoxRef.value)
        listBoxRef.value.style.animationDelay =
          (props.idx || 0) * 3 > 10
            ? `${((props.idx || 0) * 3) / 10}s`
            : `0.${(props.idx || 0) * 3}s`;
    });

    return {
      listBoxRef,
      buttonClickHandler
    };
  }
});

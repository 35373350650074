const ABOUT_FACE = 'ico_face';
const ABOUT_OCR = 'ico_ocr';
const ABOUT_TTS = 'ico_tts';
const ABOUT_STT = 'ico_stt';
const ABOUT_AI = 'ico_ai';

export const ABOUT_TYPES = {
  ABOUT_FACE,
  ABOUT_OCR,
  ABOUT_TTS,
  ABOUT_STT,
  ABOUT_AI
};

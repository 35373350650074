































import { defineComponent, onMounted, Ref, ref } from '@vue/composition-api';

import { ABOUT_TYPES } from '@/commons/constants/about-type';
import { i18nTxt } from '@/commons/i18n/index';
import ListBox from '@/components/about/ListBox.vue';

export default defineComponent({
  name: 'About',
  components: {
    ListBox
  },
  setup(props, { emit }) {
    const isPageStart = ref(false);
    const isSectionStart = ref(false);
    const aboutSection: Ref<HTMLDivElement | null> = ref(null);

    const scrollEventHandler = () => {
      if (window.pageYOffset > 100) {
        isSectionStart.value = true;
      }
    };

    onMounted(() => {
      emit('emitIsHomeFocus', false, true);
      window.addEventListener('scroll', scrollEventHandler);
      setTimeout(() => {
        isPageStart.value = true;
      }, 100);
      setTimeout(() => {
        isSectionStart.value = true;
      }, 1400);
    });

    return {
      i18nTxt,
      ABOUT_TYPES,
      isPageStart,
      isSectionStart,
      aboutSection
    };
  }
});
